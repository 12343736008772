<template>
  <div class="page-main home">
    <div v-if="showPage">
      <a-carousel class="home-slider" :autoplay="true">
        <template v-for="(item, index) in slider" :key="index">
          <div><img :src="item.slider_image" alt="" class="slider-img" /></div>
        </template>
      </a-carousel>
      <div class="yxzx-divider"></div>
      <div class="course-box">
        <div class="course-box-title">
          <div><fire-two-tone two-tone-color="#e72929" /> 热门课程</div>
          <!-- <div class="course-box-title-more">更多 <right-outlined /></div> -->
        </div>
        <div class="course-box-main">
          <template v-for="(item, index) in hotCourse" :key="index">
            <div class="course-item" @click="goToCourse(item)">
              <img :src="item.course_image" class="course-poster" />
              <div class="course-name">{{ item.course_name }}</div>
              <div class="course-view">
                <eye-outlined /> {{ item.course_view_num }}次播放
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- <camera-tool :closable="true"></camera-tool> -->
    </div>
    <div v-else class="page-loading">
      <a-spin size="large" class="mb-30"></a-spin>
      <div>正在跳转中，请稍后……</div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { getSliderList, getCourseList } from "@/api/main.js";
import { message } from "ant-design-vue";
import { EyeOutlined, FireTwoTone } from "@ant-design/icons-vue";
import url from "@/utils/url.js";
// import HomeCourseList from "./components/course-list.vue";
import { getUserId } from "@/utils/storeData.js";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
// import CameraTool from "@/components/cameratool/index.vue";
import { encode } from "js-base64";

export default defineComponent({
  name: "Home",
  components: {
    // HelloWorld,
    // RightOutlined,
    EyeOutlined,
    FireTwoTone,
    // VideoTool,
    // HomeCourseList,
    // CameraTool,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const pageData = reactive({
      slider: [],
      hotCourse: [],
      jobList: [],
      user_id: computed(() => getUserId()),
      showPage: false,
    });
    getSliderList()
      .then((res) => {
        if (res.code == 200) {
          // console.log(res.data.slider.filter((res) => res.slider_type == 2));
          pageData.slider = res.data.slider;
          // .filter(
          //   (res) => res.slider_type == 2
          // );
        } else {
          message.error(res.msg);
        }
      })
      .catch((res) => {
        console.log(res);
      });

    getCourseList({
      page: 1,
      limit: 8,
      course_ishot: 1,
      user_id: pageData.user_id,
    })
      .then((res) => {
        if (res.code == 200) {
          pageData.hotCourse = res.data.course;
        } else {
          message.error(res.msg);
        }
      })
      .catch((res) => {
        console.log(res);
      });

    const goToCourse = (_course) => {
      url.navigateTo("/course/course", {
        t: encode(`${_course.course_id},${_course?.student_id}`),
      });
    };
    watch(
      () => route.query,
      () => {
        let nameList = Object.keys(route.query);
        let userId = store.getters["usrId"];
        if (!userId && nameList.indexOf("studentCode") != -1) {
          pageData.showPage = false;
          store
            .dispatch("LOGIN", { ...route.query })
            .then(() => {
              url.navigateTo("/mine", { type: "course" });
            })
            .catch((res) => {
              if (res.code != 200) {
                url.replaceTo("/index");
              }
            });
        } else {
          pageData.showPage = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      ...toRefs(pageData),
      goToCourse,
    };
  },
});
</script>
<style lang="less" scoped>
.home {
  padding: 20px 0 50px;
  .home-slider {
    width: 100%;
    .slider-img {
      width: 100%;
      height: 300px;
      display: block;
    }
  }
  .course-box {
    width: 100%;
    margin-bottom: 30px;
    .course-box-title {
      font-size: 20px;
      font-weight: 700;
      color: #333;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      &-more {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        cursor: pointer;
        margin-right: 0;
        margin-left: auto;
        flex: 0 0 auto;
      }
      &-more:hover {
        color: #1890ff;
      }
      .job-first {
        flex: 0 0 auto;
      }
      .job-second-box {
        margin-left: 16px;
        margin-right: 16px;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
        overflow-x: auto;
        overflow-y: hidden;
        &-item {
          position: relative;
          // background-color: #ececec;
          padding: 0 8px;
          // border: 1px solid #fff;
          margin-right: 8px;
          border-radius: 8px;
          cursor: pointer;
          flex: 1 0 auto;
          white-space: nowrap;
        }
        .job-second-active {
          color: #545c63;
          font-weight: 700;
          position: relative;
        }
        .job-second-active::after {
          content: "";
          color: #999;
          width: calc(100% - 16px);
          height: 2px;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #e72929;
        }
      }
    }
    .course-box-main {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .course-item {
        position: relative;
        width: 282px;
        // height: 20px;
        margin-right: 24px;
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 6px 10px 0 #5f656926;
        cursor: pointer;
        transition: all 0.2s;
        .course-poster {
          width: 100%;
          height: 165px;
          border-radius: 8px 8px 0 0;
          display: block;
          margin-bottom: 8px;
        }
        .course-name {
          width: 90%;
          font-size: 15px;
          margin: 0 auto;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 8px;
          color: #545c63;
        }
        .course-view {
          width: 90%;
          margin: 0 auto;
          font-size: 13px;
          padding-bottom: 16px;
          // text-align: right;
          color: #9199a1;
        }
      }
      .course-item:nth-child(4n) {
        margin-right: 0;
      }
      .course-item:hover {
        box-shadow: 0 6px 10px 0 #5f656960;
        transform: translateY(-4px);
      }
    }
  }
  .page-loading {
    padding-top: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
